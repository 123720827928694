var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('table',{staticClass:"table",staticStyle:{"width":"100%"}},[_vm._m(0),_c('tbody',_vm._l((_vm.students),function(student){return _c('tr',{key:student.PupilID},[_c('td',[_vm._v(_vm._s(student))]),_c('td',{staticClass:"is-vcentered"},[_c('figure',{staticClass:"image is-32x32"},[_c('img',{attrs:{"src":`data:image/png;base64,${
                  student.photo ||
                  'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAALUlEQVR42u3NMQ0AAAgDsE051lHBQdIaaDM5VYFAIBAIBAKBQCAQCAQCwadgAbuSGAE8Ej4FAAAAAElFTkSuQmCC'
                }`}})])]),_c('td',{staticClass:"is-vcentered"},[_vm._v(" "+_vm._s(student.PupilFirstName)+" "+_vm._s(student.PupilLastName)+" ")]),_c('td',{staticClass:"is-vcentered"},[_c('div',{staticClass:"field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(student.present),expression:"student.present"}],staticClass:"switch",attrs:{"id":'presentSwitch' + student.PupilID,"type":"checkbox"},domProps:{"checked":Array.isArray(student.present)?_vm._i(student.present,null)>-1:(student.present)},on:{"change":function($event){var $$a=student.present,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(student, "present", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(student, "present", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(student, "present", $$c)}}}}),_c('label',{attrs:{"for":'presentSwitch' + student.PupilID}})])]),_c('td',{staticClass:"is-vcentered"},[_c('div',{staticClass:"field"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(student.lunchChoice),expression:"student.lunchChoice"}],attrs:{"disabled":!student.present || !_vm.enabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(student, "lunchChoice", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("NO LUNCH")]),_vm._l((_vm.menu?.options ?? {}),function(option,optionName){return _c('option',{key:optionName,domProps:{"value":optionName}},[_vm._v(" "+_vm._s(optionName)+" ")])})],2)])]),(_vm.menu?.options && _vm.menu.options[student.lunchChoice])?_vm._l((_vm.menu.options?.[student.lunchChoice]
                ?.options),function(option,oIndex){return _c('td',{key:oIndex},[_c('label',[_vm._v(_vm._s(option.title))]),_c('ul',_vm._l((option.choose),function(optionV){return _c('li',{key:optionV},[_c('label',{staticClass:"checkbox is-flex is-flex-direction-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        student.selectedOptions[
                          option.title + '_#_' + optionV
                        ]
                      ),expression:"\n                        student.selectedOptions[\n                          option.title + '_#_' + optionV\n                        ]\n                      "}],attrs:{"type":"checkbox","id":option.title + '_#_' + optionV,"disabled":_vm.selectedOptionDisabled(
                          student,
                          option,
                          option.title + '_#_' + optionV
                        )},domProps:{"value":option.title + '_#_' + optionV,"checked":Array.isArray(
                        student.selectedOptions[
                          option.title + '_#_' + optionV
                        ]
                      )?_vm._i(
                        student.selectedOptions[
                          option.title + '_#_' + optionV
                        ]
                      ,option.title + '_#_' + optionV)>-1:(
                        student.selectedOptions[
                          option.title + '_#_' + optionV
                        ]
                      )},on:{"change":function($event){var $$a=
                        student.selectedOptions[
                          option.title + '_#_' + optionV
                        ]
                      ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.title + '_#_' + optionV,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(student.selectedOptions, 
                          option.title + '_#_' + optionV
                        , $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(student.selectedOptions, 
                          option.title + '_#_' + optionV
                        , $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(student.selectedOptions, 
                          option.title + '_#_' + optionV
                        , $$c)}}}}),_vm._v(" "+_vm._s(optionV)+"   ")])])}),0),(option.limit > 0)?_c('p',{staticClass:"has-text-right has-text-weight-semibold is-italic is-size-7"},[_vm._v(" Choose any "+_vm._s(option.limit)+" item"+_vm._s(option.limit > 1 ? "s" : "")+". ")]):_vm._e()])}):_vm._e()],2)}),0)]),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("Student")]),_c('th',[_vm._v("Present")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Lunch")])])])
}]

export { render, staticRenderFns }