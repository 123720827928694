<template>
  <div>
    <section>
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <th colspan="2">Student</th>
            <th>Present</th>
            <th colspan="2">Lunch</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in students" :key="student.PupilID">
            <td>{{ student }}</td>
            <td class="is-vcentered">
              <figure class="image is-32x32">
                <img
                  :src="`data:image/png;base64,${
                    student.photo ||
                    'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAALUlEQVR42u3NMQ0AAAgDsE051lHBQdIaaDM5VYFAIBAIBAKBQCAQCAQCwadgAbuSGAE8Ej4FAAAAAElFTkSuQmCC'
                  }`"
                />
              </figure>
            </td>
            <td class="is-vcentered">
              {{ student.PupilFirstName }} {{ student.PupilLastName }}
            </td>
            <td class="is-vcentered">
              <div class="field">
                <input
                  :id="'presentSwitch' + student.PupilID"
                  type="checkbox"
                  class="switch"
                  v-model="student.present"
                />
                <label :for="'presentSwitch' + student.PupilID"></label>
              </div>
            </td>

            <td class="is-vcentered">
              <div class="field">
                <select
                  v-model="student.lunchChoice"
                  :disabled="!student.present || !enabled"
                >
                  <option value="">NO LUNCH</option>
                  <option
                    v-for="(option, optionName) in menu?.options ?? {}"
                    :key="optionName"
                    :value="optionName"
                  >
                    {{ optionName }}
                  </option>
                </select>
              </div>
            </td>
            <template v-if="menu?.options && menu.options[student.lunchChoice]">
              <td
                v-for="(option, oIndex) in menu.options?.[student.lunchChoice]
                  ?.options"
                :key="oIndex"
              >
                <label>{{ option.title }}</label>
                <ul>
                  <li v-for="optionV in option.choose" :key="optionV">
                    <label class="checkbox is-flex is-flex-direction-row">
                      <input
                        type="checkbox"
                        :value="option.title + '_#_' + optionV"
                        v-model="
                          student.selectedOptions[
                            option.title + '_#_' + optionV
                          ]
                        "
                        :id="option.title + '_#_' + optionV"
                        :disabled="
                          selectedOptionDisabled(
                            student,
                            option,
                            option.title + '_#_' + optionV
                          )
                        "
                      />
                      {{ optionV }} &nbsp;
                    </label>
                  </li>
                </ul>
                <p
                  class="has-text-right has-text-weight-semibold is-italic is-size-7"
                  v-if="option.limit > 0"
                >
                  Choose any {{ option.limit }} item{{
                    option.limit > 1 ? "s" : ""
                  }}.
                </p>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <button class="button" @click="save()">Save</button>
    </section>
  </div>
</template>

<script>
import functions from "helpers/functions.js";
import lunchHelper from "helpers/lunch.js";
import timetable from "helpers/timetable";

export default {
  name: "daybooklist",

  props: ["group", "gid"],

  data() {
    return {
      localGroup: null,
      loaded: false,
      menu: null,
      orders: null,
      students: [],
      groupDetails: [],
    };
  },

  mounted() {
    let parts = functions.groupID(this.gid);
    this.groupDetails = parts;

    /*this.$api.get("v2/staff/lunch/group/" + parts[0], true, true).then(response => {
            console.log(response)
            this.menu = response.menu;
            this.orders = response.groupOrders
        })*/
  },

  watch: {
    "$store.state.currentRegGroup": {
      async handler(_value) {
        let parts = functions.groupID(this.gid);
        this.groupDetails = parts;

        this.$api
          .get("v2/staff/lunch/group/" + parts[0], true, true)
          .then((response) => {
            console.log(response);
            this.menu = response.menu;
            this.orders = response.groupOrders;
            this.updateGroupInfo();
          });
      },
      immediate: true,
    },
  },

  computed: {
    enabled() {
      //  console.log([this.$store.state.currentHM, this.$store.state.currentHM < "02:00"])
      return lunchHelper.kitchenOpen(this.menu, this.$store.state.currentHM);
    },
  },

  methods: {
    updateGroupInfo() {
      let t = {};

      let marks = this.$store.state.currentRegGroup?.marks;

      let students = this.$store.state.currentGroup?.group?.students;
      let studentDetails =
        this.$store.state.currentGroup?.group?.studentDetails;

      if (!marks || !students) {
        this.students = [];
        return;
      }

      Object.entries(marks).forEach((x) => {
        t[x[1].ident] = x[1].code;
      });

      let result = [];

      let regDetails = this.$store.state.currentRegGroup.students;

      console.log("XXXXXXXXXXXXXXXXXAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", students);

      students.forEach((element) => {
        const pOrder = this.orders?.[element]?.[1] ?? [];
        const pOrderHash = pOrder.reduce((p, c) => {
          p[c] = 1;
          return p;
        }, {});

        let sDetails = studentDetails[element];

        const student = {
          id: regDetails[element]?.RegisterMarkID,
          mark: t[regDetails[element]?.RegisterMarkID] ?? undefined,
          present: regDetails[element]?.RegisterMarkID === 1,
          PupilFirstName: sDetails.PupilFirstName,
          PupilID: element,
          PupilLastName: sDetails.PupilLastName,
          photo: sDetails.photo,
          lunchChoice: this.orders?.[element]?.[0] ?? "",
          selectedOptions: pOrderHash,
        };

        result.push(student);
      });

      this.students = result;
    },
    save() {
      let data = {
        groupid: this.groupDetails[0],
        students: this.students
          .filter((x) => x.present)
          .map((x) => {
            return x.PupilID.toString();
          }),
        lunches: this.students.map((x) => {
          return [
            x.PupilID.toString(),
            [x.lunchChoice, Object.keys(x.selectedOptions)],
          ];
        }),
        mode: "Present",
        pid: this.groupDetails[1],
        date: this.groupDetails[2],
      };
      console.log(data);

      timetable.presentPeriod(data).then((rtn) => {
        this.$store.commit("updateCurrentRegGroup", rtn);
      });
    },

    selectedOptionDisabled(student, option, link) {
      if (option.limit == 0) {
        return false;
      }

      if (student.selectedOptions[link]) {
        return false;
      }

      if (
        Object.keys(student.selectedOptions).filter(
          (x) =>
            x.startsWith(option.title + "_#_") && student.selectedOptions[x]
        ).length < option.limit
      ) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style>
.ve-table
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  tbody.ve-table-body
  .ve-table-expand-tr
  .ve-table-expand-td-content {
  font-size: 16px;
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  background-color: lightgrey;
}

.icon-close {
  color: red;
}
</style>
